import { atom } from 'recoil'

type MusicNft = {
  id?: string
  chain_id: number
  contract_address: string
  token_id: string
  name: string
  image: string
  sound: string
  artist_name: string
  artist_public_id: string
}

export const playQueueState = atom({
  key: 'playQueueState',
  default: [] as Array<MusicNft>,
})
